const OPTIONS = {
  year: 'numeric' as const,
  month: 'long' as const,
  day: 'numeric' as const,
};

/**
 * Parses the input date, enforcing UTC if specified.
 * Assumes input strings are in ISO-like format (`YYYY-MM-DD` or `YYYY-MM-DDTHH:mm:ss`).
 */
const parseDate = (value: string | number, utc: boolean): Date => {
  if (typeof value === 'number') {
    return new Date(value); // Timestamps are safe for Date constructor
  }

  if (utc) {
    // Add Z to enforce UTC if T is missing
    if (!value.includes('T')) {
      return new Date(`${value}T00:00:00Z`);
    }

    return new Date(value); // ISO strings with T or Z are handled correctly
  }

  return new Date(value); // Assume local time for non-UTC strings
};

/**
 * By default the date will use the user's timezone
 * (e.g. "2023-07-22" could be displayed as "July 22" or "July 21").
 * Set `useUtc: true` to display the same date no matter the user's timezone.
 */
const formatDate = (
  value: string | number | Date,
  { locale = 'en-US', useUtc = false } = {}
) => {
  if (value === null || value === undefined || value === '') return '';

  const date = value instanceof Date ? value : parseDate(value, useUtc);

  // not all browsers have Intl API
  if (typeof Intl === 'undefined' || !Intl.DateTimeFormat) {
    return useUtc ? date.toUTCString() : date.toString();
  }

  return new Intl.DateTimeFormat(
    locale,
    useUtc ? { ...OPTIONS, timeZone: 'UTC' } : OPTIONS
  ).format(date);
};

export default formatDate;
