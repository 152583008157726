import React, { memo, useState } from 'react';
import ShowListItem from './ShowListItem';
import { MappedShow, ShowsSectionProps } from './types';
import { useI18n } from '~/app/lib/i18n';
import SectionTitle from '../lib/SectionTitle';
import Box from '~/app/components/Box';
import Text from '~/app/components/Text';
import Clickable from '~/app/components/Clickable';
import { usePageTheme } from '../../ItemPageEdit/addons/theme/PageThemeContext';

const DEFAULT_MAX_ITEMS = 5;

interface ShowListProps {
  items: MappedShow[];
  title: ShowsSectionProps['title'];
}

const ShowList = memo<ShowListProps>(({ items = [], title }) => {
  const { t } = useI18n();
  const [isExpanded, setIsExpanded] = useState(false);
  const pageTheme = usePageTheme();

  const itemsToRender = isExpanded ? items : items.slice(0, DEFAULT_MAX_ITEMS);
  const hasMoreItems = items.length > DEFAULT_MAX_ITEMS;

  return (
    <div>
      {title && <SectionTitle padding="0 0 2.3rem" text={title} />}
      <Box padding="0 1.5rem">
        {itemsToRender.map((item, index) => {
          const isFirst = !index;

          return (
            <ShowListItem
              key={item.url}
              item={item}
              margin={`${!isFirst ? '1rem' : '0'} 0 0`}
            />
          );
        })}
      </Box>
      {hasMoreItems && !isExpanded && (
        <Clickable
          testId="showMoreShows"
          tabIndex={0}
          onClick={() => setIsExpanded(true)}
          margin="2.2rem 0 0 0"
          withHoverOpacityFrom={0.8}
        >
          <Text size="1.7rem" isBold centered family={pageTheme.fontFamily}>
            {t('app.actions.showMore')}
          </Text>
        </Clickable>
      )}
    </div>
  );
});

export default ShowList;
